import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-grid-toll-tag',
  templateUrl: './grid-toll-tag.component.html',
  styleUrls: ['./grid-toll-tag.component.scss']
})
export class GridTollTagComponent implements OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

}
