import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
    @Input() data: any;
    @Input() type: string;
    @Input() active: boolean;
    @Input() lang: string;

    @Output() closePageOutput = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
    }

    public questionClick(item): void {
        if (item.active) {
            item.active = false;
        } else {
            for (const q of this.data.questions) {
                q.active = false;
            }

            item.active = true;
        }
    }

    public closePage(event): void {
        event.preventDefault();
        this.closePageOutput.emit(true);
    }

    public handleTelLink(tel): string {
        return `tel:${tel.replace(/\./g, '')}`;
    }
}
