import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

// import { SlickModule } from 'ngx-slick';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PageComponent } from './page/page.component';
import { GridTollTagComponent } from './grid-toll-tag/grid-toll-tag.component';

@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    GridTollTagComponent
  ],
  imports: [
    BrowserModule,
    SlickCarouselModule,
    // SlickModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
